import React, { useState } from "react";

const Home = () => {
  const [contador, setContador] = useState(0);
  const [contadorReal, setContadorReal] = useState(0);
  const [contadorRealBaraja, setContadorRealBaraja] = useState(0);
  const [baraja, setBaraja] = useState(6);
  const [inputValue, setInputValue] = useState("");
  const [inputValueBaraja, setInputValueBaraja] = useState(6);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      updateContador(inputValue);
      setInputValue("");
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (value === "j" || value === "q" || value === "k" || value === "a") {
      value = value.toUpperCase();
    }
    setInputValue(value);
  };

  const updateContador = (value) => {
    if (["2", "3", "4", "5", "6"].includes(value)) {
      setContador(contador + 1);
      setContadorReal(contadorReal + 1)
      setContadorRealBaraja(Math.round((contadorReal + 1) / baraja))
    } else if (["7", "8", "9"].includes(value)) {
      setContador(contador);
      setContadorReal(contadorReal + 1)
      setContadorRealBaraja(Math.round((contadorReal + 1) / baraja))
    } else if (["10", "J", "Q", "K", "A"].includes(value)) {
      setContador(contador - 1);
      setContadorReal(contadorReal + 1)
      setContadorRealBaraja(Math.round((contadorReal + 1) / baraja))
    }
  };

  const handlerBaraja = (e) => {
    setBaraja(e.target.value)
    setInputValueBaraja(e.target.value);
  };

  const handleKeyDownBaraja = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      setContadorRealBaraja(Math.round(contadorReal / baraja))
    }
  };

  return (
    <>
      <style>
        {`
          .columns {
            height: 100vh;
            align-items: center;
            justify-content: center;
          }

          .contador-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px; 
          }

          .contador-container p {
            padding: 10px;
            border-radius: 5px;
            font-size: 1.5rem;
            font-weight: bold;
          }

          .input-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px; 
          }

          .input-container label {
            margin-right: 10px; 
          }
        `}
      </style>
      <div className="columns is-centered is-vcentered is-family-monospace is-size-1 has-text-weight-bold">
        <div className="column is-half has-text-centered">
          <div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
            <div className="column">
              <div className="field">
                <label className="is-size-3">
                  Contador de cartas - Black Jack
                </label>
                <br />
                <div className="control">
                  <div className="input-container">
                    <label>
                      Ingrese carta:
                      <input
                        className="input"
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    </label>
                    <label>
                      Ingrese Nùmero Barajas
                      <input
                        className="input"
                        type="text"
                        defaultValue={baraja}
                        value={inputValueBaraja}
                        onChange={handlerBaraja}
                        onKeyDown={handleKeyDownBaraja}
                      />
                    </label>
                  </div>
                </div>
                <label className="is-size-4 mr-5">
                  Contador:{" "}
                  <span
                    style={{
                      backgroundColor:
                        contador < 0
                          ? "red"
                          : contador > 0
                            ? "forestgreen"
                            : "yellow",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {contador >= 1 && "+"} {contador}
                  </span>
                </label>
                <label className="is-size-4 ml-5">
                  Contador Real:{" "}
                  <span
                    style={{
                      backgroundColor:
                        contadorRealBaraja < 0
                          ? "red"
                          : contadorRealBaraja > 0
                            ? "forestgreen"
                            : "yellow",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {contadorRealBaraja >= 1 && "+"} {contadorRealBaraja}
                  </span>
                </label>
                <div className="contador-container">
                  <button
                    className="button is-danger"
                    onClick={() => {setContador(0);setContadorReal(0);setContadorRealBaraja(0)}}
                  >
                    Reiniciar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;